import { gql } from "@apollo/client";

export const getCampaignListing = gql`
  query ListCampaigns($filter: CampaignFilter) {
    listCampaigns(filter: $filter) {
      id
      title
      titleShort
      category
      type
      activeStartDate
      activeEndDate
      segments
      campaignStatus
      displayPosition
      imageUrl
      hasSegments
      activityTypes
      endless
      activities {
        id
        name
        description
        activityData
        activityRules {
          id
          ruleData
        }
      }
      rewardGroups {
        id
        type
        amount
      }
    }
  }
`;

export const getCampaignsTaggedToCodeGroup = gql`
  query listCampaigns($filter: CampaignFilter) {
    listCampaigns(filter: $filter) {
      id
      title
      activityTypes
      activeStartDate
      segments
      codeGroups
    }
  }
`;

export const getActiveCampaignsList = gql`
  query ListCampaigns($filter: CampaignFilter) {
    listCampaigns(filter: $filter) {
      id
      title
      type
      activeStartDate
      activeEndDate
      campaignContent {
        title
        description
        contentData
      }
    }
  }
`;
